<template>
  <div>
    <div v-if="loading">
      Loading...
    </div>
    <div class="pa-2" v-else>
      <div>
        <h3 class="font-weight-bold">{{ unit.shortName }} - {{ unit.title }}</h3>
      </div>
      <div>
        <v-expansion-panels>
          <!-- BASE DATA -->
          <v-expansion-panel>
            <v-expansion-panel-header>Base Data</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form>
                <v-text-field
                  label="Short Name"
                  v-model="unit.shortName"
                />

                <v-text-field
                  label="Title"
                  v-model="unit.title"
                />

                <v-select
                  label="Project Phase"
                  :items="projectPhases"
                  item-text="name"
                  item-value="value"
                  v-model="unit.projectPhase"
                />

                <div class="d-flex">
                  <v-spacer />
                  <v-btn color="green" :loading="saveLoading" @click="saveUnit">Save</v-btn>
                </div>
              </v-form>

            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- TEAMS -->
          <v-expansion-panel>
            <v-expansion-panel-header>Teams</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn @click="createNewTeam">Create Team</v-btn>

              <v-data-table
                :items="teams"
                :headers="teamsHeaders"
              >
                <template #item.members="{ item }">

                </template>
                <template #item.actions="{ item }">
                  <v-btn @click="addUserToTeam(item)">Add User</v-btn>
                  <v-btn @click="deleteTeam(item)">Delete</v-btn>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- GOALS -->
          <v-expansion-panel>
            <v-expansion-panel-header>Goals</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex justify-end">
                <v-dialog v-model="createGoalDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">Create Goal</v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Create Goal</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="availableGoals"
                            item-text="name"
                            item-value="@id"
                            v-model="createGoalModel.goal"
                          ></v-select>
                        </v-col>
                        <v-col>
                          <v-text-field
                            label="Count"
                            v-model="createGoalModel.count"
                          ></v-text-field>
                        </v-col>
                      </v-row>


                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="createGoalDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="createGoal"
                      >
                        Create
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                </v-dialog>
              </div>

              <v-data-table
                :items="goals"
                :headers="goalsHeaders"
              >

                <template #item.actions="{item}">
                  <v-btn text @click="deleteGoal(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, watchEffect, ref, watch} from "vue";
import {CLIENT} from "@/api/client";
import {byApi, byApiId} from "@/api/helpers";
import Intranet from "@/core/intranet";

export default defineComponent({
  name: "se2a-admin-unit-details",
  props: {
    unitId: {
      required: true
    }
  },
  setup(props) {
    const loading = ref(true);
    const unit = ref({});

    const loadUnit = () => {
      CLIENT.go(byApiId('units', props.unitId)).get().then(value => {
        unit.value = value.rawData;
      }).finally(() => {
        loading.value = false;
      })
    }

    const goals = ref([]);
    const loadGoals = () =>  {
      CLIENT.go(unit.value['@id'] + '/goals').get().then(value => {
        goals.value = value.asPaginationResult().membersData();
      })
    }

    const teams = ref([]);
    const loadTeams = () => {
      CLIENT.go(unit.value['@id'] + '/teams').get().then(value => {
        teams.value = value.asPaginationResult().membersData();
      })
    }

    watch(loading, value => {
      if(!value) {
        loadGoals();
        loadTeams();
      }
    })

    const createNewTeam = () => {
      CLIENT.go(byApi('teams')).post({
        unit: unit.value['@id']
      }).then(value => {
        loadTeams();
      })
    };

    const deleteTeam = (team) => {
      CLIENT.go(team['@id']).delete().then(() => {
        loadTeams();
      })
    };

    const addUserToTeam = (team) => {
      Intranet.modals().openModal('se2a-admin-unit-details-team-add-user', {
        team: team
      })
    };

    const saveLoading = ref(false);
    const saveUnit = () => {
      saveLoading.value = true;
      CLIENT.go(unit.value['@id']).patch(unit.value).then(value => {
        unit.value = value.rawData;
      }).finally(() => saveLoading.value = false)
    }

    // load unit on mount
    onMounted(loadUnit);

    const projectPhases = [
      {name: 'Project Phase 1', value: 'p1'},
      {name: 'Project Phase 2', value: 'p2'},
      {name: 'Project Phase 1/2', value: 'pb'}
    ]

    const goalsHeaders = [
      {text: 'Name', value: 'goal.name'},
      {text: 'Required Count', value: 'value'},
      {text: 'Actual Count', value: 'count'},
      {text: 'Actions', value: 'actions'}
    ]

    const teamsHeaders = [
      {text: 'Id', value: 'id'},
      {text: 'Members', value: 'membershipName'},
      {text: 'Actions', value: 'actions'}
    ];

    const createGoalDialog = ref(false);
    const availableGoals = ref([]);

    watch(createGoalDialog, (value, oldValue, onCleanup) => {
      if(value) {
        fetchGoals();
      }
    });

    const createGoalModel = ref({
      goal: "",
      count: 0
    });
    const createGoal = () => {

      CLIENT.go(byApi('publication_unit_goal_targets')).post({
        goal: createGoalModel.value.goal,
        unit: unit.value['@id'],
        value: parseInt(createGoalModel.value.count)
      }).then(value => {
        createGoalDialog.value = false;
        createGoalModel.value = {
          goal: "",
          count: 0
        };

        loadGoals();
      })
    }

    const deleteGoal = (goal) => {
      CLIENT.go(goal['@id']).delete().then(() => {
        loadGoals();
      })
    }

    const fetchGoals = () => {
      if(availableGoals.value.length !== 0) {
        return;
      }

      CLIENT.go(byApi('publication_goals')).get().then(value => {
        availableGoals.value = value.asPaginationResult().membersData();
      });
    }

    return {
      createGoalDialog, availableGoals, createGoalModel, createGoal, deleteGoal,
      loading, unit,
      saveUnit, saveLoading,
      projectPhases,
      goals, goalsHeaders,
      teams, teamsHeaders, createNewTeam, deleteTeam, addUserToTeam
    }
  }
});
</script>

<style scoped>

</style>
